import { extend } from 'vee-validate'
import { required, email, max, min, confirmed } from "vee-validate/dist/rules"

extend('required', {
    ...required,
    message: 'O campo {_field_} é obrigatório'
});

extend("email", {
    ...email,
    message: "O {_field_} informado é inválido"
});

extend('max', {
    ...max,
    message: "O campo {_field_} atingiu o limite máximo de caracteres"
});

extend('min', {
    ...min,
    message: "O campo {_field_} atingiu o limite minimo de caracteres"
});

extend('confirmed', {
    ...confirmed,
    message: 'O campo {_field_} e obrigatório'
});

extend('cpf', {
    validate(value) {
        if (value !== null && value.length === 14) {
            const cpf = value.replace(/\D/g, "")

            if (cpf.length !== 11) {
                return false
            }

            if (/^(\d)\1+$/.test(cpf)) {
                return false
            }

            let soma = 0

            for (let i = 0; i < 9; i++) {
                soma += parseInt(cpf[i]) * (10 - i)
            }

            let primeiroDigito = 11 - (soma % 11)

            if (primeiroDigito >= 10) {
                primeiroDigito = 0
            }

            if (primeiroDigito !== parseInt(cpf[9])) {
                return false
            }

            soma = 0

            for (let i = 0; i < 10; i++) {
                soma += parseInt(cpf[i]) * (11 - i)
            }

            let segundoDigito = 11 - (soma % 11)

            if (segundoDigito >= 10) {
                segundoDigito = 0
            }

            if (segundoDigito !== parseInt(cpf[10])) {
                return false
            }

            return true
        }

        return false
    },
    message: "O {_field_} informado é invalido"
});

extend('cnpj', {
    validate(value) {
        if (value !== null) {
            const cnpj = value.replace(/\D/g, "")
            if (cnpj.length !== 14) {
                return false
            }

            if (/^(\d)\1{13}$/.test(cnpj)) {
                return false
            }

            const calcularDigito = (CNPJ, peso) => {
                let soma = 0

                for (let i = 0; i < CNPJ.length; i++) {
                    soma += parseInt(CNPJ[i]) * peso[i]
                }

                const resto = soma % 11

                return resto < 2 ? 0 : 11 - resto
            }

            const baseCnpj = cnpj.slice(0, 12)
            const digito1  = calcularDigito(baseCnpj, [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])
            const digito2  = calcularDigito(baseCnpj + digito1, [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2])

            return (cnpj === baseCnpj + digito1 + digito2)
        }

        return false
    },
    message: "O {_field_} informado é invalido"
});

extend('validade', {
	validate(value) {
		if (!/^\d{2}\/\d{2}$/.test(value)) {
			return false;
		}

		const [month, year] = value.split('/').map(Number);

		if (month < 1 || month > 12) {
			return false;
		}

		const currentDate = new Date();
		const currentMonth = currentDate.getMonth() + 1;
		const currentYear = currentDate.getFullYear() % 100;

		if (year < currentYear) {
			return false;
		}

		if (year === currentYear && month <= currentMonth) {
			return false;
		}

		return true;
	},
	message: "A {_field_} informada é invalido"
});

extend('creditCardLength', {
    params: ['bandeira'],
    validate(value, { bandeira }) {
      const cleanValue = value.replace(/\D/g, '');
      
      const cardLengths = {
        american_express: [15],
        diners_club: [14],
        discover: [16, 19],
        elo: [16],
        hiper: [16, 19],
        hipercard: [16, 19],
        jcb: [16, 19],
        mastercard: [16],
        visa: [13, 16, 19],
      };
  
      if (!bandeira || !cardLengths[bandeira]) {
        return false;
      }
  
      return cardLengths[bandeira].includes(cleanValue.length);
    },
    message: 'Número de cartão inválido para a bandeira selecionada'
  });
